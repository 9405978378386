import { Trans } from 'react-i18next'

import { Typography } from '@mui/material'
import { ChartTooltip } from 'core/components'
import { OrderItem } from 'core/types'
import { COLORS } from 'finanse/helpers'
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts'

interface VendorsChartProps {
  ordersData: OrderItem[]
}

const groupedItems = (items: OrderItem[]): { counterparty: string; totalCost: number }[] =>
  items.reduce((acc: any, item) => {
    const existingItem: any = acc.find((entry: any) => entry.counterparty === item.counterparty)

    if (existingItem) {
      existingItem.totalCost += item.totalCost
    } else {
      acc.push({ counterparty: item.counterparty, totalCost: item.totalCost })
    }

    return acc
  }, [])

const VendorsChart = ({ ordersData }: VendorsChartProps) => {
  const mappedData = groupedItems(ordersData).map(item => ({ ...item, totalCost: parseFloat(item.totalCost.toFixed(2)) }))

  const favoriteSupplier = mappedData.reduce((max, item) => (item.totalCost > max.totalCost ? item : max), {
    counterparty: '',
    totalCost: 0,
  })
  return (
    <>
      <ResponsiveContainer width="99%" height={420}>
        <PieChart width={310} height={310}>
          <Pie dataKey="totalCost" nameKey="counterparty" isAnimationActive={false} data={mappedData} outerRadius={80} label>
            {mappedData.map((el, idx) => (
              <Cell key={`cell-${el.counterparty}`} fill={COLORS[idx % COLORS.length]} />
            ))}
          </Pie>
          <Tooltip
            wrapperStyle={{ width: '100%' }}
            position={{ y: -10, x: 0 }}
            content={({ active, label, payload }: any) => (
              <ChartTooltip
                active={active}
                label={label}
                payload={payload?.map((el: any) => ({
                  name: el.name,
                  value: el.value,
                  color: el.payload.fill,
                }))}
                rounded
              />
            )}
          />
        </PieChart>
      </ResponsiveContainer>
      <Typography my={1}>
        <Trans i18nKey="dashboard.favoriteSupplier" values={{ favSup: favoriteSupplier.counterparty }} />
      </Typography>
    </>
  )
}

export { VendorsChart }
