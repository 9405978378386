import apiEndpoints from 'api/endpoints'
import { useRequest } from 'core/hooks'
import { GetParams, PaginatedResult, PosIngredient } from 'core/types'

type GetPosIngredientsParams = {
  deliverySiteId: number | undefined
} & GetParams

export const useGetPosIngredientNames = (params: GetPosIngredientsParams) => {
  const { data, isLoading, ...other } = useRequest<PaginatedResult<PosIngredient>>(apiEndpoints.getPosIngredientsNames(params), {
    enabled: !!params.deliverySiteId,
  })
  return { isLoadingPosIngredients: isLoading, posIngredients: data, ...other }
}
