import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Box, Divider, Grid, Typography, useTheme } from '@mui/material'
import { ChartLegend, ChartTooltip, MainPaper, Select } from 'core/components'
import Loader from 'core/components/Loader'
import { formatPrice, getMonthsList, getYearsList } from 'core/utils'
import dayjs from 'dayjs'
import { COLORS, getUniqueKeys, mapPaymentMethodsData, mapVendorData } from 'finanse/helpers'
import { useDeliverySiteDetailedReport } from 'finanse/hooks'
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'

interface DSDetailedReportProps {
  selectedClient: string
}

const DSDetailedReport = ({ selectedClient }: DSDetailedReportProps) => {
  const { t } = useTranslation()

  const [detailedYear, setDetailedYear] = useState(dayjs().year().toString())
  const [month, setMonth] = useState(dayjs().month().toString())

  const { dsDetailedReportData, isLoadingDSDetailedReport } = useDeliverySiteDetailedReport({
    clientId: selectedClient,
    year: detailedYear,
    month: (Number(month) + 1).toString(),
  })

  return (
    <Box position="relative">
      {isLoadingDSDetailedReport && <Loader relative />}
      <Divider sx={{ mt: 6, mb: 4 }} />
      <Typography variant="h3" mb={5}>
        {t('finanse.detailedData')}
      </Typography>
      <Box mb={4}>
        <Select
          label={t('common.year')}
          name="detailed-year"
          value={detailedYear}
          onChange={e => setDetailedYear(e.target.value)}
          options={getYearsList(2)}
          sx={{ width: { xs: '100%', sm: '8rem' }, mr: { sm: 2 }, mb: { xs: 2, sm: 0 } }}
        />
        <Select
          label={t('finanse.Month')}
          name="month"
          value={month}
          onChange={e => setMonth(e.target.value)}
          options={getMonthsList()}
          sx={{ width: { xs: '100%', sm: '10rem' } }}
        />
      </Box>
      {dsDetailedReportData && (
        <>
          {dsDetailedReportData.length === 0 ? (
            <MainPaper>
              <Typography>{t('finanse.noDataFoTheSelectedMonth')}</Typography>
            </MainPaper>
          ) : (
            <>
              <Grid container mb={4}>
                <Grid item xs={12} md={6}>
                  <MainPaper sx={{ width: '100%' }}>
                    <Typography variant="subtitle1" mb={2}>
                      {t('finanse.orderValue')}
                    </Typography>
                    {dsDetailedReportData.map(el => (
                      <Grid key={el.deliverySiteName} container item alignItems="baseline" spacing={4} mb={1}>
                        <Grid item xs={8}>
                          <Typography variant="h5">{el.deliverySiteName}</Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <Typography variant="h3" color="primary">
                            {formatPrice(el.totalCost)}
                          </Typography>
                        </Grid>
                      </Grid>
                    ))}
                  </MainPaper>
                </Grid>
              </Grid>
              <Grid container spacing={2} alignItems="stretch">
                <Grid item xs={12} md={6}>
                  <CustomBarChart data={mapPaymentMethodsData(dsDetailedReportData, t)} title={t('finanse.paymentMethodsTypes')} />
                </Grid>

                <Grid item xs={12} md={6}>
                  <CustomBarChart data={mapVendorData(dsDetailedReportData)} title={t('finanse.orderedFromVendors')} />
                </Grid>
              </Grid>
            </>
          )}
        </>
      )}
    </Box>
  )
}

interface CustomBarChartProps {
  data: any[]
  title: string
}

const CustomBarChart = ({ data, title }: CustomBarChartProps) => {
  const { palette } = useTheme()

  return (
    <MainPaper sx={{ height: '100%' }}>
      <Typography variant="subtitle1" mb={4}>
        {title}
      </Typography>
      <ResponsiveContainer width="99%" height={290}>
        <BarChart width={200} height={400} data={data}>
          <CartesianGrid strokeDasharray="3 3" />

          <XAxis tick={{ fill: palette.text.secondary, fontSize: '0.825rem' }} dataKey="deliverySiteName" tickLine={false} tickMargin={8} />
          <YAxis tick={{ fill: palette.text.secondary, fontSize: '0.825rem' }} tickLine={false} tickMargin={8} />

          <Tooltip
            content={({ active, payload, label }: any) => (
              <ChartTooltip
                active={active}
                label={label}
                payload={payload?.map((el: any) => ({ name: el.dataKey, value: el.value, color: el.fill }))}
              />
            )}
          />
          <Legend
            content={({ payload }: any) => (
              <ChartLegend payload={payload?.map((el: any) => ({ value: el.dataKey, color: el.payload.fill }))} />
            )}
          />
          {getUniqueKeys(data).map((el, idx) => (
            <Bar key={el} dataKey={el} fill={COLORS[idx % COLORS.length]} />
          ))}
        </BarChart>
      </ResponsiveContainer>
    </MainPaper>
  )
}

export { DSDetailedReport }
