import apiEndpoints from 'api/endpoints'
import { useRequest } from 'core/hooks'
import { PosIntegrations } from 'core/types'

export const useGetPosIntegrations = (params: { ids: number[] | undefined }) => {
  const { data, ...other } = useRequest<PosIntegrations>(apiEndpoints.getPosIntegrations(params), {
    enabled: !!params.ids,
  })

  return {
    posIntegrations: data,
    ...other,
  }
}
