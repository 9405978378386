import { lazy } from 'react'
import { Navigate } from 'react-router-dom'

import { DashboardWrapper } from 'client/pages/DashboardWrapper'
import Clients from 'companies/pages/Clients'
import { Contact } from 'contact/pages/Contact'
import { PageTitleBreadcrumbs } from 'core/components'
import { GeneralRoute, UserRole } from 'core/consts'
import Finanse from 'finanse/pages/Finanse'
import { TFunction } from 'i18next'
import InvoicesList from 'invoices/pages/InvoicesList'
import AdminCatalog from 'management/components/Catalog'
import { AdministratorDotykackaData } from 'management/components/DotykackaManagement'
import MinimalPriceRules from 'management/components/MinimalPriceRulesManagement'
import PaymentsManagement from 'management/components/PaymentsMenagement'
import RulesManagement from 'management/components/RulesManagement'
import UsersManagement from 'management/components/UsersManagement'
import AdminOrders from 'management/ordersManagement/AdminOrders'
import { AdminOrdersDetails } from 'management/ordersManagement/AdminOrdersDetails'
import { AdminProductDetails } from 'management/pages/AdminProductDetails'
import DotykackaClientIntegration from 'pos/components/DotykackaClientIntegration'
import { DotykackaReport } from 'pos/components/DotykackaReport'
import { PosIngredients } from 'pos/components/DotykackaWarehouse'
import { ClientDotykackaData } from 'pos/pages'
import { TermsOfSubscription, TermsOfUsage } from 'terms'
import { StockupDotykackaWarehouse } from 'users/pages/StockupDotykackaWarehouse'
import { VendorCatalog } from 'vendor/components/VendorCatalog'
import { VendorConfiguration } from 'vendor/components/VendorManagement'
import VendorFinances from 'vendor/pages/VendorFinances'
import VendorOrders from 'vendor/pages/VendorOrders'
import VendorOrdersDetails from 'vendor/pages/VendorOrdersDetails'
import { VendorProductDetails } from 'vendor/pages/VendorProductDetails'

import {
  ClientManagementTab,
  DeliverySiteManagementTab,
  ManualTab,
  PlatformManagementTab,
  PosTab,
  RouteVal,
  VendorManagementTab,
} from './routeTypes'

// Admin
const Profile = lazy(() => import('admin/pages/Profile'))
const PlatformManagement = lazy(() => import('management/pages/PlatformManagement'))
const SingleVendorManagement = lazy(() => import('management/components/VendorsManagement/SingleVendorManagement'))
const VendorsManagement = lazy(() => import('management/components/VendorsManagement'))
const AddEditVendorMainData = lazy(() => import('management/components/VendorsManagement/vendorData/AddEditVendorMainData'))
const UploadCatalog = lazy(() => import('management/components/VendorsManagement/vendorData/UploadCatalog'))
const EditPaymentForClient = lazy(() => import('management/components/VendorsManagement/vendorData/EditPaymentForClient'))
const DisableDeliverySite = lazy(() => import('management/components/VendorsManagement/vendorData/DisableDeliverySite'))

// Clients
const ClientsManagement = lazy(() => import('management/components/ClientsManagement'))
const SingleClientManagement = lazy(() => import('management/components/ClientsManagement/SingleClientManagement'))
const AddEditClientMainData = lazy(() => import('management/components/ClientsManagement/clientData/AddEditClientMainData'))
const ClientDetails = lazy(() => import('management/components/ClientsManagement/clientData/ClientDetails'))

// Delivery sites
const DeliverySitesManagement = lazy(() => import('management/components/DeliverySitesManagement'))
const SingleDSManagement = lazy(() => import('management/components/DeliverySitesManagement/SingleDSManagement'))
const AddEditDeliverySiteMainData = lazy(() => import('management/components/DeliverySitesManagement/deliverySiteData/AddEditMainData'))

// vendor
const VendorManagement = lazy(() => import('vendor/pages/VendorManagement'))

// Auth
const ForgotPassword = lazy(() => import('auth/pages/ForgotPassword'))
const ForgotPasswordSubmit = lazy(() => import('auth/pages/ForgotPasswordSubmit'))
const ActivateAccount = lazy(() => import('auth/pages/ActivateAccount'))
const Login = lazy(() => import('auth/pages/Login'))
const Register = lazy(() => import('auth/pages/Register'))
// Core
const Forbidden = lazy(() => import('core/pages/Forbidden'))
const NotFound = lazy(() => import('core/pages/NotFound'))
const UnderConstructions = lazy(() => import('core/pages/UnderConstructions'))
const Manual = lazy(() => import('core/pages/Manual'))
const Faq = lazy(() => import('core/pages/Manual/Faq'))
const GoodPractices = lazy(() => import('core/pages/Manual/GoodPractices'))

// Orders
const Basket = lazy(() => import('orders/pages/Basket'))
const UserOrders = lazy(() => import('users/pages/UserOrders'))
const UserOrderDetails = lazy(() => import('users/pages/UserOrderDetails'))

// Catalog
const Catalog = lazy(() => import('items/pages/Catalog'))

// OrdersWithoutLoggingIn
const AcceptOrders = lazy(() => import('orders/pages/AcceptOrder'))
const ProcessOrders = lazy(() => import('orders/pages/StartProcessing'))

//ShoppingList
const ShoppingListList = lazy(() => import('shoppingLists/pages/ShoppingListList'))
const ShoppingListDetails = lazy(() => import('shoppingLists/pages/ShoppingListDetails'))
const ShoppingListEdit = lazy(() => import('shoppingLists/pages/ShoppingListEdit'))
const LandingPage = lazy(() => import('landing/pages/LandingPage'))

export interface SingleRoute {
  title?: React.ReactNode
  path: string
  element: React.ReactElement
  roles?: UserRole[]
  subroutes?: SingleRoute[]
  noAuth?: boolean
  fullWidth?: boolean
}

export const routes = (t: TFunction, roles: string[]): SingleRoute[] => [
  {
    path: '/home',
    element: <DashboardWrapper />,
    roles: [UserRole.CLIENT, UserRole.ADMIN, UserRole.VENDOR, UserRole.RESTAURANT_MANAGER],
  },
  {
    path: 'client-finances',
    title: t('page.title.clientFinances'),
    element: <Finanse />,
  },
  {
    path: 'client-invoices',
    title: t('page.title.clientInvoices'),
    element: <InvoicesList />,
    roles: [UserRole.CLIENT],
  },
  {
    path: 'catalog',
    title: t('page.title.catalog'),
    element: <Catalog />,
    roles: [UserRole.CLIENT, UserRole.RESTAURANT_MANAGER],
  },
  {
    path: 'shopping-list',
    title: t('page.title.shoppingList'),
    element: <ShoppingListList />,
    roles: [UserRole.CLIENT, UserRole.RESTAURANT_MANAGER],
  },
  {
    path: 'shopping-list/:uuid',
    title: (
      <PageTitleBreadcrumbs
        items={[{ title: 'page.title.shoppingList', to: '/shopping-list' }, { title: 'page.title.shoppingListDetails' }]}
      />
    ),
    element: <ShoppingListDetails />,
    roles: [UserRole.CLIENT, UserRole.RESTAURANT_MANAGER],
  },
  {
    path: 'shopping-list/:uuid/edit',
    title: (
      <PageTitleBreadcrumbs
        items={[{ title: 'page.title.shoppingList', to: '/shopping-list' }, { title: 'page.title.shoppingListEdit' }]}
      />
    ),
    element: <ShoppingListEdit />,
    roles: [UserRole.CLIENT, UserRole.RESTAURANT_MANAGER],
  },
  {
    path: 'restaurants',
    title: t('page.title.restaurants'),
    element: <Clients />,
    roles: [UserRole.CLIENT],
  },
  {
    path: 'pos',
    title: t('page.title.pos'),
    element: <ClientDotykackaData />,
    roles: [UserRole.CLIENT],
    subroutes: [
      {
        path: PosTab.WAREHOUSE,
        title: 'pos.warehouse',
        element: <PosIngredients />,
      },
      {
        path: PosTab.RECENTLY_SOLD,
        title: 'pos.recentlySold',
        element: <DotykackaReport />,
      },
    ],
  },
  {
    path: 'my-orders',
    title: t('page.title.myOrders'),
    element: <UserOrders />,
    roles: [UserRole.CLIENT, UserRole.RESTAURANT_MANAGER],
  },
  {
    path: 'vendor-orders',
    title: t('page.title.vendorOrders'),
    element: <VendorOrders />,
    roles: [UserRole.VENDOR],
  },
  {
    path: 'vendor-catalog',
    title: t('page.title.vendorCatalog'),
    element: <VendorCatalog />,
    roles: [UserRole.VENDOR],
  },
  {
    path: 'vendor-catalog/:uuid/edit',
    title: (
      <PageTitleBreadcrumbs
        items={[{ title: 'page.title.vendorCatalog', to: '/vendor-catalog' }, { title: 'page.title.vendorProductEdit' }]}
      />
    ),
    element: <VendorProductDetails />,
    roles: [UserRole.VENDOR],
  },
  {
    path: 'vendor-finances',
    title: t('page.title.vendorFinances'),
    element: <VendorFinances />,
    roles: [UserRole.VENDOR],
  },
  {
    path: 'my-orders/:uuid',
    title: <PageTitleBreadcrumbs items={[{ title: 'page.title.myOrders', to: '/my-orders' }, { title: 'page.title.orderDetails' }]} />,
    element: <UserOrderDetails />,
    roles: [UserRole.CLIENT, UserRole.RESTAURANT_MANAGER],
  },
  {
    path: 'my-orders/:uuid/stockup-dotykacka-warehouse',
    title: (
      <PageTitleBreadcrumbs
        items={[{ title: 'page.title.myOrders', to: '/my-orders' }, { title: 'page.title.stockupDotykackaWarehouse' }]}
      />
    ),
    element: <StockupDotykackaWarehouse />,
    roles: [UserRole.CLIENT, UserRole.RESTAURANT_MANAGER],
  },
  {
    path: 'vendor-orders/:uuid',
    title: t('page.title.orderDetails'),
    element: <VendorOrdersDetails />,
    roles: [UserRole.VENDOR],
  },
  {
    path: 'users-management',
    title: t('page.title.usersManagement'),
    element: <UsersManagement />,
    roles: [UserRole.ADMIN],
  },
  {
    path: RouteVal.VENDORS_MANAGEMENT,
    title: t('page.title.vendorManagement'),
    element: <VendorsManagement />,
    roles: [UserRole.ADMIN],
  },
  {
    path: `${RouteVal.VENDORS_MANAGEMENT}/add-vendor`,
    title: (
      <PageTitleBreadcrumbs
        items={[{ title: 'page.title.vendorManagement', to: `/${RouteVal.VENDORS_MANAGEMENT}` }, { title: 'page.title.addVendorData' }]}
      />
    ),
    element: <AddEditVendorMainData />,
    roles: [UserRole.ADMIN],
  },
  {
    path: `${RouteVal.VENDORS_MANAGEMENT}/:uuid`,
    title: (
      <PageTitleBreadcrumbs
        items={[{ title: 'page.title.vendorManagement', to: `/${RouteVal.VENDORS_MANAGEMENT}` }, { title: 'page.title.editVendorData' }]}
      />
    ),
    element: <SingleVendorManagement />,
    roles: [UserRole.ADMIN],
    subroutes: [
      {
        path: VendorManagementTab.ADD_VENDOR,
        title: 'vendors.addVendor',
        element: <AddEditVendorMainData />,
      },
      {
        path: VendorManagementTab.MAIN_DATA,
        title: 'vendors.mainData',
        element: <AddEditVendorMainData />,
      },
      {
        path: VendorManagementTab.CLIENT_PAY_METHODS,
        title: 'vendors.clientPaymentMethods',
        element: <EditPaymentForClient />,
      },
      {
        path: VendorManagementTab.DISABLE_DSITE,
        title: 'vendors.disableDSiteForVendor.title',
        element: <DisableDeliverySite />,
      },
      {
        path: VendorManagementTab.CATALOG_UPLOAD,
        title: 'vendors.catalogUpload.title',
        element: <UploadCatalog />,
      },
    ],
  },
  {
    path: RouteVal.CLIENTS_MANAGEMENT,
    title: t('page.title.clientManagement'),
    element: <ClientsManagement />,
    roles: [UserRole.ADMIN],
  },
  {
    path: `${RouteVal.CLIENTS_MANAGEMENT}/${ClientManagementTab.ADD_CLIENT}`,
    title: (
      <PageTitleBreadcrumbs
        items={[
          { title: roles.includes(UserRole.ADMIN) ? 'page.title.clientManagement' : 'page.title.restaurants', to: '/restaurants' },
          { title: 'page.title.addClientData' },
        ]}
      />
    ),
    element: <AddEditClientMainData />,
    roles: [UserRole.CLIENT],
  },
  {
    path: `${RouteVal.CLIENTS_MANAGEMENT}/:uuid/${ClientManagementTab.DETAILS}`,
    title: (
      <PageTitleBreadcrumbs
        items={[
          {
            title: roles.includes(UserRole.ADMIN) ? 'page.title.clientManagement' : 'page.title.restaurants',
            to: roles.includes(UserRole.ADMIN) ? `/${RouteVal.CLIENTS_MANAGEMENT}` : '/restaurants',
          },
          { title: 'clients.details' },
        ]}
      />
    ),
    element: <ClientDetails />,
    roles: [UserRole.CLIENT, UserRole.ADMIN],
  },
  {
    path: `${RouteVal.CLIENTS_MANAGEMENT}/:uuid`,
    title: (
      <PageTitleBreadcrumbs
        items={[
          {
            title: roles.includes(UserRole.ADMIN) ? 'page.title.clientManagement' : 'page.title.restaurants',
            to: roles.includes(UserRole.ADMIN) ? `/${RouteVal.CLIENTS_MANAGEMENT}` : '/restaurants',
          },
          { title: 'page.title.editClientData' },
        ]}
      />
    ),
    element: <SingleClientManagement />,
    roles: [UserRole.ADMIN, UserRole.CLIENT],
    subroutes: [
      {
        path: ClientManagementTab.MAIN_DATA,
        title: 'clients.mainData',
        element: <AddEditClientMainData />,
      },
    ],
  },
  // Delivery sites
  {
    path: RouteVal.DS_MANAGEMENT,
    title: t('page.title.deliverySiteManagement'),
    element: <DeliverySitesManagement />,
    roles: [UserRole.ADMIN],
  },
  {
    path: `${RouteVal.DS_MANAGEMENT}/${DeliverySiteManagementTab.ADD_DELIVERY_SITE}`,
    title: (
      <PageTitleBreadcrumbs
        items={[
          { title: roles.includes(UserRole.ADMIN) ? 'page.title.deliverySiteManagement' : 'page.title.restaurants', to: '/restaurants' },
          { title: 'page.title.addDeliverySiteData' },
        ]}
      />
    ),
    element: <AddEditDeliverySiteMainData />,
    roles: [UserRole.CLIENT],
  },
  {
    path: `${RouteVal.DS_MANAGEMENT}/:uuid`,
    title: (
      <PageTitleBreadcrumbs
        items={[
          {
            title: roles.includes(UserRole.ADMIN) ? 'page.title.deliverySiteManagement' : 'page.title.restaurants',
            to: roles.includes(UserRole.ADMIN) ? `/${RouteVal.DS_MANAGEMENT}` : '/restaurants',
          },
          { title: 'page.title.editDeliverySiteData' },
        ]}
      />
    ),
    element: <SingleDSManagement />,
    roles: [UserRole.ADMIN, UserRole.CLIENT],
    subroutes: [
      {
        path: DeliverySiteManagementTab.MAIN_DATA,
        title: 'clients.mainData',
        element: <AddEditDeliverySiteMainData />,
      },
    ],
  },
  // END
  {
    path: 'payments-management',
    title: t('page.title.paymentsManagement'),
    element: <PaymentsManagement />,
    roles: [UserRole.ADMIN],
  },
  {
    path: '/dotykacka',
    title: t('page.title.dotykacka'),
    element: <AdministratorDotykackaData />,
    roles: [UserRole.ADMIN],
  },
  {
    path: 'admin-catalog',
    title: t('page.title.catalog'),
    element: <AdminCatalog />,
    roles: [UserRole.ADMIN],
  },
  {
    path: 'admin-catalog/:uuid/edit',
    title: (
      <PageTitleBreadcrumbs items={[{ title: 'page.title.catalog', to: '/admin-catalog' }, { title: 'page.title.adminProductEdit' }]} />
    ),
    element: <AdminProductDetails />,
    roles: [UserRole.ADMIN],
  },
  {
    path: 'admin-orders',
    title: t('page.title.vendorOrders'),
    element: <AdminOrders />,
    roles: [UserRole.ADMIN],
  },
  {
    path: 'admin-orders/:uuid',
    title: t('page.title.orderDetails'),
    element: <AdminOrdersDetails />,
    roles: [UserRole.ADMIN],
  },
  {
    path: 'vendor-configuration',
    title: t('page.title.vendorConfiguration'),
    element: <VendorConfiguration />,
    roles: [UserRole.VENDOR],
  },
  {
    path: 'vendor-configuration/:uuid',
    title: (
      <PageTitleBreadcrumbs
        items={[{ title: 'page.title.vendorConfiguration', to: '/vendor-configuration' }, { title: 'page.title.editVendorData' }]}
      />
    ),
    element: <VendorManagement />,
    roles: [UserRole.VENDOR],
    subroutes: [
      {
        path: VendorManagementTab.MAIN_DATA,
        title: 'vendors.mainData',
        element: <AddEditVendorMainData />,
      },
    ],
  },
  {
    path: 'basket',
    title: t('page.title.basket'),
    element: <Basket />,
  },
  {
    path: 'profile',
    title: t('page.title.profile'),
    element: <Profile />,
  },
  {
    path: 'platform-settings',
    title: t('page.title.platformSettings'),
    element: <PlatformManagement />,
    roles: [UserRole.ADMIN],
    //can not delete it, even though it is unnecessary
    subroutes: [
      {
        path: PlatformManagementTab.PRICE_RULES,
        title: 'platformManagement.rules.priceRules',
        element: <RulesManagement />,
      },
      {
        path: PlatformManagementTab.MINIMAL_PRICE,
        title: 'platformManagement.rules.priceRules',
        element: <MinimalPriceRules />,
      },
    ],
  },
  {
    path: 'manual',
    element: <Manual />,
    fullWidth: true,
    noAuth: true,
    subroutes: [
      {
        path: ManualTab.FAQ,
        title: 'admin.drawer.menu.faq',
        element: <Faq />,
      },
      {
        path: ManualTab.GOOD_PRACTICES,
        title: 'admin.drawer.menu.goodPractices',
        element: <GoodPractices />,
      },
    ],
  },
  ...notProtectedRoutes,
]

export const notProtectedRoutes = [
  {
    path: 'acceptOrder',
    element: <AcceptOrders />,
    noAuth: true,
  },
  {
    path: 'startProcessing',
    element: <ProcessOrders />,
    noAuth: true,
  },
  {
    path: 'forgot-password',
    element: <ForgotPassword />,
    noAuth: true,
  },
  {
    path: 'resetPassword',
    element: <ForgotPasswordSubmit />,
    noAuth: true,
  },
  {
    path: 'activate',
    element: <ActivateAccount />,
    noAuth: true,
  },
  {
    path: 'login',
    element: <Login />,
    noAuth: true,
  },
  {
    path: 'contact',
    element: <Contact />,
    noAuth: true,
  },
  {
    path: 'register',
    element: <Register />,
    noAuth: true,
  },
  {
    path: 'under-construction',
    element: <UnderConstructions />,
    noAuth: true,
  },
  {
    path: '403',
    element: <Forbidden />,
    noAuth: true,
  },
  {
    path: '404',
    element: <NotFound />,
    noAuth: true,
  },
  {
    path: 'integration-pos',
    element: <DotykackaClientIntegration />,
    roles: [UserRole.CLIENT],
    noAuth: true,
  },
  {
    path: GeneralRoute.TERMS_OF_SUBSCRIPTION,
    element: <TermsOfSubscription />,
    noAuth: true,
  },
  {
    path: GeneralRoute.TERMS_OF_USAGE,
    element: <TermsOfUsage />,
    noAuth: true,
  },
  {
    path: '/',
    element: <LandingPage />,
    noAuth: true,
  },
  {
    path: GeneralRoute.LANDING_PAGE,
    element: <Navigate to="/" replace />,
    noAuth: true,
  },

  {
    path: '*',
    element: <Navigate to="/404" replace />,
    noAuth: true,
  },
]
