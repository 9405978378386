import apiEndpoints from 'api/endpoints'
import { PaymentTypeVal } from 'core/consts'
import { useMutate, useRequest } from 'core/hooks'

export const useGetPossiblePaymentMethodsToChange = (params: { orderId: string }, disabled?: boolean) => {
  const { data, isFetching, isError, ...other } = useRequest<PaymentTypeVal[]>(apiEndpoints.getPossiblePaymentMethodsToChange(params), {
    enabled: !!params.orderId && !disabled,
  })
  return { paymentMethodsIsFetching: isFetching, paymentMethodsData: data, paymentMethodsIsError: isError, ...other }
}

export const useChangeOrderPaymentMethod = () => {
  const { mutate, ...others } = useMutate(apiEndpoints.putChangeOrderPaymentMethod())
  return { mutateChangeOrderPaymentMethod: mutate, ...others }
}
