import { memo } from 'react'

import { Box, Grid, Typography } from '@mui/material'
import { MainPaper } from 'core/components'
import { formatPrice } from 'core/utils'

export interface ChartTooltipProps {
  label?: string
  active: boolean
  payload: { name: string; value: number | string; color: string }[] | undefined
  value?: string
  rounded?: boolean
}

export const ChartTooltip = memo(({ active, payload, label, value, rounded }: ChartTooltipProps) => {
  if (active && payload && (payload.length || value)) {
    return (
      <MainPaper sx={{ px: 1.5, py: 1, minWidth: '16rem' }}>
        {label && (
          <Typography variant="body2" color="text.secondary" mb={1}>
            {label}
          </Typography>
        )}
        {value && <Typography variant="subtitle2">{formatPrice(Number(value))}</Typography>}

        {payload?.map(el => (
          <Grid container alignItems="baseline" key={el.name} spacing={1}>
            <Grid item xs={1}>
              <Box sx={{ width: '0.725rem', height: '0.725rem', bgcolor: el.color, borderRadius: rounded ? '50%' : 0 }} />
            </Grid>
            <Grid item xs={7}>
              <Typography variant="body2">{el.name}</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="subtitle2">{formatPrice(Number(el.value))}</Typography>
            </Grid>
          </Grid>
        ))}
      </MainPaper>
    )
  }

  return null
})
