import { Checkbox, InputAdornment } from '@mui/material'
import { Autocomplete, TextField } from 'core/components'
import { FilterName, Order, PosIngredient, SelectOption } from 'core/types'
import { TFunction } from 'i18next'

type Actions = {
  handleAutocompleteChange: (productId: string, ingredientName: SelectOption) => void
  handleCheckboxChange: (productId: string) => void
  handleQuantityOverrideChange: (productId: string, newQuantity: number) => void
}

const getUnitForSelectedIngredient = (selectedIngredient: SelectOption | null, posIngredients: PosIngredient[] | undefined) => {
  if (selectedIngredient && posIngredients) {
    const foundIngredient = posIngredients.find(ingredient => ingredient.productName === selectedIngredient.name)
    return foundIngredient?.unit
  }
}

export const mapStockupDotykackaWarehouseDialogData = (
  data: Order | undefined,
  action: Actions,
  ingredientMatches: { productId: string; ingredientName: SelectOption | null; quantityOverride: number }[],
  posIngredients: PosIngredient[] | undefined,
  t: TFunction,
) => {
  const mappedData = data?.productOrders
    .filter(x => !(x.isRemoved || x.isOverriden))
    .map(el => {
      const ingredientMatch = ingredientMatches.find(match => match.productId === el.productId)
      const selectedIngredient = ingredientMatch?.ingredientName

      const unit = selectedIngredient ? getUnitForSelectedIngredient(selectedIngredient, posIngredients) : el.unit

      return {
        rowId: el.productId,
        data: {
          checkbox: <Checkbox checked={!!selectedIngredient} onChange={() => action.handleCheckboxChange(el.productId)} />,
          quantity: el.quantity,
          productName: el.productName,
          productNameInDotykacka: (
            <Autocomplete
              sx={{ minWidth: '10rem', maxWidth: '18rem' }}
              filterName={FilterName.POS_PRODUCT_NAME}
              apiRequest="getPosIngredientsNames"
              apiParams={{ deliverySiteId: data.deliverySite.id }}
              value={ingredientMatches.find(element => element.productId === el.productId)?.ingredientName ?? null}
              setValue={(val: SelectOption) => action.handleAutocompleteChange(el.productId, val)}
              addNewOption
            />
          ),
          quantityToStockup: (
            <TextField
              type="number"
              name="quantityToStockup"
              value={ingredientMatches.find(match => match.productId === el.productId)?.quantityOverride || 0}
              onChange={event => action.handleQuantityOverrideChange(el.productId, parseFloat(event.target.value))}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {unit ? t(`product.unitValShort.${unit}`) : t(`product.unitValShort.${el.unit}`)}
                  </InputAdornment>
                ),
              }}
              sx={{ minWidth: '10rem', maxWidth: '18rem' }}
            />
          ),
        },
        styles: selectedIngredient && { backgroundColor: 'grey.50' },
      }
    })
  return mappedData
}

export const mapStockupDotykackaWarehouseDialogColumns = (t: TFunction) => [
  { title: '', id: 'checkbox' },
  { title: t('orders.stockupDotykackaWarehouse.quantity'), id: 'quantity' },
  { title: t('orders.stockupDotykackaWarehouse.productName'), id: 'productName' },
  { title: t('orders.stockupDotykackaWarehouse.productNameInDotykacka'), id: 'productNameInDotykacka' },
  { title: t('orders.stockupDotykackaWarehouse.quantityToStockup'), id: 'quantityToStockup' },
]
