import apiEndpoints from 'api/endpoints'
import { useRequest } from 'core/hooks'
import { GetParams, OrderItem, PaginatedResult } from 'core/types'

type GetOrdersParams = {
  OrderRole: number
  DeliverySiteId?: string
  PaymentMethod?: string
  VendorId?: string
  Status?: string
} & GetParams

export const useOrders = (params: GetOrdersParams) => {
  const { data, isLoading, ...other } = useRequest<PaginatedResult<OrderItem>>(apiEndpoints.getOrders(params), { keepPreviousData: true })
  return { isLoadingOrders: isLoading, ordersData: data, ...other }
}
