import { memo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Box, Divider, Grid, Typography, useTheme } from '@mui/material'
import { ChartLegend, ChartTooltip, Loader, MainPaper, Select } from 'core/components'
import { formatPrice, getMonthsList, getYearsList } from 'core/utils'
import dayjs from 'dayjs'
import { COLORS } from 'finanse/helpers'
import { useClientDetailedReport } from 'finanse/hooks'
import { Bar, BarChart, CartesianGrid, Legend, Rectangle, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'

import { DetailedReportPieChart } from './DetailedReportPieChart'

interface ClientDetailedReportProps {
  selectedClient: string
}

const ClientDetailedReport = ({ selectedClient }: ClientDetailedReportProps) => {
  const { t } = useTranslation()
  const { palette } = useTheme()

  const [detailedYear, setDetailedYear] = useState(dayjs().year().toString())
  const [month, setMonth] = useState(dayjs().month().toString())

  const { clientDetailedReportData, isLoadingClientDetailedReport } = useClientDetailedReport({
    clientId: selectedClient,
    year: detailedYear,
    month: (Number(month) + 1).toString(),
  })

  const replaceDataKeys = (obj: any) => {
    return Object.fromEntries(Object.entries(obj).map(([key, value]) => [t(`payment.paymentsMethodVal.${key}`), value]))
  }

  return (
    <Box position="relative">
      {isLoadingClientDetailedReport && <Loader relative />}
      <Divider sx={{ mt: 6, mb: 4 }} />
      <Typography variant="h3" mb={5}>
        {t('finanse.detailedData')}
      </Typography>
      <Box mb={4}>
        <Select
          label={t('common.year')}
          name="detailed-year"
          value={detailedYear}
          onChange={e => setDetailedYear(e.target.value)}
          options={getYearsList(2)}
          sx={{ width: { xs: '100%', sm: '8rem' }, mr: { sm: 2 }, mb: { xs: 2, sm: 0 } }}
        />
        <Select
          label={t('finanse.Month')}
          name="month"
          value={month}
          onChange={e => setMonth(e.target.value)}
          options={getMonthsList()}
          sx={{ width: { xs: '100%', sm: '10rem' } }}
        />
      </Box>
      {clientDetailedReportData && (
        <>
          {clientDetailedReportData.totalCost === 0 ? (
            <MainPaper>
              <Typography>{t('finanse.noDataFoTheSelectedMonth')}</Typography>
            </MainPaper>
          ) : (
            <>
              <Grid container spacing={2} alignItems="stretch" mb={4}>
                <Grid item xs={12} sm={6} md={4}>
                  <MainPaper>
                    <Typography variant="subtitle1">{t('finanse.orderValue')}</Typography>
                    <Typography variant="h3" color="primary" mt={2}>
                      {formatPrice(clientDetailedReportData.totalCost)}
                    </Typography>
                  </MainPaper>
                </Grid>
              </Grid>
              <Grid container spacing={2} alignItems="stretch">
                <Grid item xs={12} md={6}>
                  <MainPaper sx={{ height: '100%' }}>
                    <Typography variant="subtitle1" mb={4}>
                      {t('finanse.paymentMethodsTypes')}
                    </Typography>

                    <ResponsiveContainer width="99%" height={290}>
                      <BarChart
                        width={200}
                        height={400}
                        data={[{ name: t('payment.paymentsMethods'), ...replaceDataKeys(clientDetailedReportData.costsPerPaymentMethod) }]}
                      >
                        <CartesianGrid strokeDasharray="3 3" />

                        <XAxis
                          tick={{ fill: palette.text.secondary, fontSize: '0.825rem' }}
                          dataKey="name"
                          tickLine={false}
                          tickMargin={8}
                        />
                        <YAxis tick={{ fill: palette.text.secondary, fontSize: '0.825rem' }} tickLine={false} tickMargin={8} />

                        <Tooltip content={<CustomTooltip />} />
                        <Legend
                          content={({ payload }: any) => (
                            <ChartLegend payload={payload?.map((el: any) => ({ value: el.dataKey, color: el.payload.fill }))} />
                          )}
                        />

                        {Object.keys(replaceDataKeys(clientDetailedReportData.costsPerPaymentMethod)).map((el, idx) => (
                          <Bar
                            key={el}
                            dataKey={el}
                            fill={COLORS[idx % COLORS.length]}
                            activeBar={<Rectangle fill="pink" stroke="blue" />}
                          />
                        ))}
                      </BarChart>
                    </ResponsiveContainer>
                  </MainPaper>
                </Grid>

                <Grid item xs={12} md={6}>
                  <DetailedReportPieChart costsPerVendor={clientDetailedReportData.costsPerVendor} />
                </Grid>
              </Grid>
            </>
          )}
        </>
      )}
    </Box>
  )
}
const CustomTooltip = memo(({ active, payload, label }: any) => {
  return (
    <ChartTooltip
      active={active}
      label={label}
      payload={payload?.map((el: any) => ({ name: el.dataKey, value: el.value, color: el.fill }))}
    />
  )
})

export { ClientDetailedReport }
