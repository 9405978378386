import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router'

import { LoadingButton } from '@mui/lab'
import { Alert, Box, Button, Link, Typography } from '@mui/material'
import { ContentItem, MainPaper, MainTable, NotReadyWrapper, PaperTitle } from 'core/components'
import { useSnackbar } from 'core/contexts/SnackbarProvider'
import { useStockupDotykackaWarehouse } from 'core/hooks/api'
import { ProductOrder, SelectOption } from 'core/types'
import { useGetOrderDetails } from 'orders/hooks'
import { mapStockupDotykackaWarehouseDialogColumns, mapStockupDotykackaWarehouseDialogData } from 'users/helpers'
import { useGetPosIngredientNames } from 'users/hooks'

export const StockupDotykackaWarehouse = () => {
  const snackbar = useSnackbar()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { uuid: orderId } = useParams()
  const {
    data: orderData,
    refetch: refetchOrder,
    isLoading: isLoadingOrderData,
    isSuccess: isSuccessOrderData,
    isError: isErrorOrderData,
  } = useGetOrderDetails(orderId ?? '')

  useEffect(() => {
    if (orderData && !orderData.stockupClientWarehousePossible) {
      navigate(`/my-orders/${orderId}`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderData])

  const { stockupDotykackaWarehouse, isLoading: isLoadingStockupDotykackaWarehouse } = useStockupDotykackaWarehouse()

  const { posIngredients } = useGetPosIngredientNames({ deliverySiteId: orderData?.deliverySite.id })

  const [ingredientMatches, setIngredientMatches] = useState<
    { productId: string; ingredientName: SelectOption | null; quantityOverride: number }[]
  >([])

  useEffect(() => {
    const initialMatches = orderData?.productOrders
      .filter(x => !(x.isRemoved || x.isOverriden))
      .map((productOrder: ProductOrder) => ({
        productId: productOrder.productId,
        ingredientName: productOrder.recognizedPosProductName
          ? { id: productOrder.recognizedPosProductName, name: productOrder.recognizedPosProductName }
          : null,
        quantityOverride: productOrder.quantity,
      }))

    setIngredientMatches(initialMatches ?? [])
  }, [orderData])

  const handleCheckboxChange = (productId: string) => {
    setIngredientMatches(prevState => prevState.map(el => (el.productId === productId ? { ...el, ingredientName: null } : el)))
  }

  const handleAutocompleteChange = (productId: string, ingredientName: SelectOption) => {
    setIngredientMatches(prevState => prevState.map(el => (el.productId === productId ? { ...el, ingredientName } : el)))
  }

  const handleQuantityOverrideChange = (productId: string, quantityOverride: number) => {
    setIngredientMatches(prevState => prevState.map(el => (el.productId === productId ? { ...el, quantityOverride } : el)))
  }

  const onStockupDotykackaWarehouse = async (
    ingredientMatches: { productId: string; ingredientName: SelectOption | null; quantityOverride: number }[],
  ) => {
    const transformedMatches = ingredientMatches
      .filter(el => el.ingredientName)
      .map(match => ({
        productId: match.productId,
        ingredientName: match.ingredientName?.name,
        quantityOverride: match.quantityOverride,
      }))
    await stockupDotykackaWarehouse(
      { orderId, ingredientMatches: transformedMatches },
      {
        onSuccess: () => {
          snackbar.success(t('orders.stockupDotykackaWarehouse.sentSuccessfully'))
          refetchOrder()
          navigate(`/my-orders/${orderId}`)
        },
        onError: () => {
          snackbar.error(t('orders.stockupDotykackaWarehouse.sendingError'))
        },
      },
    )
  }
  const filledIngredientCount = ingredientMatches.filter(match => match.ingredientName !== null).length
  const totalProductCount = orderData?.productOrders.filter(x => !(x.isRemoved || x.isOverriden)).length
  return (
    <NotReadyWrapper isLoading={isLoadingOrderData} isSuccess={isSuccessOrderData} isError={isErrorOrderData}>
      <MainPaper>
        <PaperTitle
          title={
            <>
              <ContentItem label={t('userOrders.restaurant')} value={orderData?.deliverySite.name} inline />
              <ContentItem label={t('userOrders.friendlyOrderNumber')} value={orderData?.friendlyOrderNumber} inline />
            </>
          }
        />
        <MainTable
          columns={mapStockupDotykackaWarehouseDialogColumns(t)}
          rows={mapStockupDotykackaWarehouseDialogData(
            orderData,
            {
              handleAutocompleteChange,
              handleCheckboxChange,
              handleQuantityOverrideChange,
            },
            ingredientMatches,
            posIngredients?.items,
            t,
          )}
        />

        <Typography variant="h4" align="center" my={3}>
          {t('orders.stockupDotykackaWarehouse.info', { quantity: `${filledIngredientCount}/${totalProductCount}` })}
        </Typography>
        <Alert variant="outlined" severity="info">
          <Typography variant="body2">{t('orders.stockupDotykackaWarehouse.warning')}</Typography>
        </Alert>

        <Box sx={{ display: 'flex', gap: 2, justifyContent: 'flex-end', mt: 2 }}>
          <Button color="primary" component={Link} href={`my-orders/${orderId}`}>
            {t('common.cancel')}
          </Button>
          <LoadingButton
            disabled={filledIngredientCount === 0}
            loading={isLoadingStockupDotykackaWarehouse}
            onClick={() => onStockupDotykackaWarehouse(ingredientMatches)}
            variant="contained"
            color="success"
          >
            {t('orders.stockupDotykackaWarehouse.putInWarehouse')}
          </LoadingButton>
        </Box>
      </MainPaper>
    </NotReadyWrapper>
  )
}
