import { Trans, useTranslation } from 'react-i18next'

import { Typography } from '@mui/material'
import { ChartTooltip, MainPaper } from 'core/components'
import { VendorInfo } from 'core/types'
import { COLORS } from 'finanse/helpers'
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts'

interface DetailedReportPieChartProps {
  costsPerVendor: VendorInfo[]
}

const DetailedReportPieChart = ({ costsPerVendor }: DetailedReportPieChartProps) => {
  const { t } = useTranslation()
  const roundedCostsPerVendor = costsPerVendor.map(item => ({
    ...item,
    totalCost: parseFloat(item.totalCost.toFixed(2)),
  }))

  const favoriteSupplier = roundedCostsPerVendor.reduce((max, item) => (item.totalCost > max.totalCost ? item : max), {
    vendorName: '',
    totalCost: 0,
  })

  return (
    <MainPaper>
      <Typography variant="subtitle1">{t('finanse.orderedFromVendors')}</Typography>
      <ResponsiveContainer width="99%" height={360}>
        <PieChart width={290} height={290}>
          <Pie dataKey="totalCost" nameKey="vendorName" isAnimationActive={false} data={roundedCostsPerVendor} outerRadius={80} label>
            {costsPerVendor.map((el, idx) => (
              <Cell key={`cell-${el.vendorId}`} fill={COLORS[idx % COLORS.length]} />
            ))}
          </Pie>
          <Tooltip
            wrapperStyle={{ width: '100%' }}
            position={{ y: 0, x: 0 }}
            content={({ active, label, payload }: any) => (
              <ChartTooltip
                active={active}
                label={label}
                payload={payload?.map((el: any) => ({
                  name: el.name,
                  value: el.value,
                  color: el.payload.fill,
                }))}
                rounded
              />
            )}
          />
        </PieChart>
      </ResponsiveContainer>
      <Typography my={1}>
        <Trans i18nKey="dashboard.favoriteSupplier" values={{ favSup: favoriteSupplier.vendorName }} />
      </Typography>
    </MainPaper>
  )
}

export { DetailedReportPieChart }
